<template>
  <div>
    <b-card>
      <template #header>
        UPC Generator
        <span class="float-right">
          <b-button class="mr-1" size="sm" variant="primary" @click="loadItems">Refresh</b-button>
          <b-button size="sm" variant="primary" @click="importModal = true">Import</b-button>
        </span>
      </template>
      <b-card-text>
        <div class="table">
          <b-table
          id="upc-generator-table"
          class="border-bottom"
          show-empty
          :empty-text="tableLoading ? 'Getting Data...' : 'There are no records to show'"
          :busy="tableLoading"
          :items="items"
          :fields="fields"
          :per-page="pagination.perPage"
          :current-page="pagination.currentPage"
          stacked="sm"
          hover>
            <template #cell(createdAt)="data">
              {{ data.item.createdAt }} <small v-if="data.item.timeFromNow"><em>({{ data.item.timeFromNow }})</em></small>
            </template>
            <template #cell(statusJob)="data">
              <div class="d-flex align-items-center">
                <b-badge v-if="data.item.statusJob === 0" variant="secondary" class="py-2" style="width: 5rem;">Open</b-badge>
                <b-badge v-if="data.item.statusJob > 0 && data.item.statusJob < 100" variant="warning" class="py-2" style="width: 5rem;">{{ data.item.statusJob.toFixed(2) }}%</b-badge>
                <b-badge v-if="data.item.statusJob === 100" variant="success" class="py-2" style="width: 5rem;">Complete</b-badge>
                <i v-if="data.item.statusJob > 0 && data.item.statusJob < 100" class="ml-1 fas fa-circle-notch fa-spin fa-lg"></i>
              </div>
            </template>

            <template #cell(actions)="data">
              <b-button class="mr-1" size="sm" variant="primary" @click="onViewResults(data.item)">View</b-button>
              <b-button size="sm" variant="primary" @click="onDirectDownload(data.item)">Download</b-button>
            </template>

          </b-table>
          <b-pagination
          :disabled="tableLoading"
          v-if="items.length > 0"
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          aria-controls="upc-generator-table"
          align="center"
          first-number
          last-number
          hide-goto-end-buttons
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>

    <b-modal v-model="importModal" title="Import" hide-footer no-close-on-backdrop @close="onImportCancel">
      <b-card-text>
        <b-form-group id="form-inputs" label="Enter SKUs:" label-for="input-1" >
          <b-form-textarea id="form-inputs" v-model="importForm.inputs" rows="10" @input="validateInputs"></b-form-textarea>
        </b-form-group>
        <b-form-group class="border-top mb-n1">
          <label class="mt-1">Total: {{importList.length}}</label>
          <div class="controls float-right mt-2">
            <b-button :disabled="importLoading" class="mr-1" size="sm" variant="secondary" @click="onImportCancel">Cancel</b-button>
            <b-button :disabled="importLoading" size="sm" variant="primary" @click="onImportSubmit">Submit</b-button>
          </div>
        </b-form-group>
      </b-card-text>
    </b-modal>

    <b-modal v-model="resultModal" :title="`${resultModalData && (resultModalData.batchId)} (${resultModalData ? resultModalData.total : 0})`" size="xl" hide-footer no-close-on-backdrop>
      <b-card-text>
        <div class="mb-2 d-flex justify-content-between align-items-center">
          <div class="filter">
            <b-input v-model="resultModalSearch" type="search" placeholder="Search"></b-input>
          </div>
          <div class="controls">
            <b-button :disabled="directDownloadLoading" class="mr-1" size="sm" variant="success" @click="onCopyToClipboard">Copy to Clipboard</b-button>
            <b-button :disabled="directDownloadLoading" size="sm" variant="primary" @click="onDownloadResult">{{ directDownloadLoading ? 'Downloading' : 'Download' }}</b-button>
          </div>
        </div>
        <div class="table">
          <b-table
          id="result-table"
          class="border-bottom"
          :filter="resultModalSearch"
          show-empty
          :empty-text="resultModalLoading ? 'Loading Result...' : 'There are no records to show'"
          :busy="resultModalLoading"
          :items="resultModalItems"
          :fields="resultModalFields"
          :per-page="resultModalPagination.perPage"
          :current-page="resultModalPagination.currentPage"
          small
          hover>
            <template #cell(status)="data">
              <i v-if="data.item.status === 'done'" class="fa fa-check-circle text-success px-1"></i>
              <i v-if="data.item.status === 'open'" class="fa fa-check-circle text-secondary px-1"></i>
            </template>
          </b-table>
          <b-pagination
          :disabled="resultModalLoading"
          v-if="resultModalItems.length > 0"
          v-model="resultModalPagination.currentPage"
          :total-rows="resultModalPagination.totalRows"
          :per-page="resultModalPagination.perPage"
          aria-controls="result-table"
          align="center"
          first-number
          last-number
          hide-goto-end-buttons
          ></b-pagination>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import api from '../services/apiService'
import { generateExcel } from '../services/excelService'
export default {
  data(){
    return {
      //import modal
      importModal: false,
      importLoading: false,
      importList: [],
      importForm: {
        inputs: ''
      },

      //table
      items: [],
      fields: [
        {
          key: "createdAt",
          label: "Requested Date",
          class: 'align-middle'
        },
        {
          key: "requestor",
          label: "Requested By",
          class: 'align-middle'
        },
        {
          key: "batchId",
          label: "Batch ID",
          class: 'align-middle'
        },
        {
          key: "total",
          label: "Total Items",
          class: 'align-middle'
        },
        {
          key: "statusJob",
          label: "Status",
          class: 'align-middle'
        },
        {
          key: "actions",
          label: "Actions",
          class: 'align-middle'
        }
      ],
      tableLoading: false,
      pagination: {
        currentPage: 1,
        perPage: 15,
        totalRows: 0
      },

      //result modal
      resultModal: false,
      resultModalSearch: '',
      resultModalData: null,
      resultModalItems: [],
      resultModalFields: [
        {
          key: "status",
          label: "",
          class: 'align-middle'
        },
        {
          key: "sku",
          label: "SKU",
          class: 'align-middle'
        },
        {
          key: "asin",
          label: "ASIN",
          class: 'align-middle'
        },
        {
          key: "upc",
          label: "UPC",
          class: 'align-middle'
        },
        {
          key: "brand",
          label: "Brand",
          class: 'align-middle'
        },
      ],
      resultModalLoading: false,
      resultModalPagination: {
        currentPage: 1,
        perPage: 15,
        totalRows: 0
      },

      directDownloadLoading: false
    }
  },
  created(){
    this.loadItems();
  },
  methods: {
    onImportCancel(){
      this.importModal = false
      this.importList = [],
      this.importForm.inputs = ''
    },
    async onImportSubmit(){
      this.importLoading = true
      try {
        let form = {
          upcData: this.importList
        }
        let response = await api.put(`/api/upc`, JSON.stringify(form));
        this.$bvToast.toast(response.data.message, {
          title: `Success`,
          variant: 'success',
          solid: true
        })
        this.importLoading = false
        this.onImportCancel();
        await this.loadItems();
      } catch (error) {
        this.$bvToast.toast(error.data.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
        this.importLoading = false
      }
    },
    validateInputs(){
      let IDs = this.importForm.inputs.split(/[\n\s,]+/);
      this.importList = [];
      for(let x in IDs){
        let ID = IDs[x]
        if(ID){
          this.importList.push(ID);
        }
      }
      this.importList = [...new Set(this.importList)]
    },
    async loadItems(){
      this.items = []
      this.tableLoading = true
      let response = await api.get('/api/upc')
      this.items = response.data
      this.pagination = {
        ...this.pagination,
        totalRows: response.data.length
      }
      this.tableLoading = false
    },
    async onViewResults(data){
      this.resultModalItems = []
      this.resultModal = true
      this.resultModalLoading = true
      this.resultModalData = data
      let response = await api.get(`/api/upc/batch/${data.batchId}`)
      this.resultModalItems = response.data
      this.resultModalPagination = {
        ...this.resultModalPagination,
        totalRows: response.data.length
      }
      this.resultModalLoading = false
    },
    async onCopyToClipboard(){
      let header = this.resultModalFields.filter(e => e.label).map(e => e.label).join('\t');
      let columns = this.resultModalFields.filter(e => e.label).map(e => e.key);

      let items = [];
      for(let x in this.resultModalItems){
        let resultItem = this.resultModalItems[x]
        if(resultItem.status !== 'open'){
          let row = [];
          let keys = Object.keys(resultItem);
          for(let i in keys){
            let key = keys[i]
            if(columns.includes(key)){
              row.push(resultItem[key])
            }
          }
          items.push(row.join('\t'))
        }
      }
      
      if(items.length === 0){
        this.$bvToast.toast('Unable to copy! All items on this batch are open.', {
          title: 'Warning',
          variant: 'warning',
          solid: true
        })
      }else{
        items.unshift(header)
        let res = items.join('\n')
        await navigator.clipboard.writeText(res);
        this.$bvToast.toast('Copied to Clipboard!', {
          title: `Success`,
          variant: 'success',
          solid: true
        })
      }
    },
    async onDownloadResult(){
      try {
        let sheetname = this.resultModalData.batchId
        let header = this.resultModalFields.filter(e => e.label).map(e => e.label)

        let headerKeys = this.resultModalFields.filter(e => e.label).map(e => e.key)
        
        let rows = []
        for(let x in this.resultModalItems){
          let resultItem = this.resultModalItems[x] 
          if(resultItem.status !== 'open'){
            let obj = {}
            let keys = Object.keys(resultItem);
            for(let i in keys){
              let key = keys[i]

              let headerIndex = headerKeys.indexOf(key)
              if(headerIndex > -1){
                obj[header[headerIndex]] = resultItem[key]
              }
            }
            rows.push(obj)
          }
        }

        if(rows.length === 0){
          this.$bvToast.toast('Unable to download! All items on this batch are open.', {
            title: 'Warning',
            variant: 'warning',
            solid: true
          })
        }else{
          await generateExcel({ header, rows, sheetname }, `${this.resultModalData.batchId}.xlsx`)
        }
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
      }
    },
    async onDirectDownload(data){
      this.directDownloadLoading = true
      try {
        let response = await api.get(`/api/upc/batch/${data.batchId}`)
        let resultModalItems = response.data

        let sheetname = data.batchId
        let header = this.resultModalFields.filter(e => e.label).map(e => e.label)
        let headerKeys = this.resultModalFields.filter(e => e.label).map(e => e.key)
        
        let rows = []
        for(let x in resultModalItems){
          let resultItem = resultModalItems[x] 
          if(resultItem.status !== 'open'){
            let obj = {}
            let keys = Object.keys(resultItem);
            for(let i in keys){
              let key = keys[i]

              let headerIndex = headerKeys.indexOf(key)
              if(headerIndex > -1){
                obj[header[headerIndex]] = resultItem[key]
              }
            }
            rows.push(obj)
          }
        }
        if(rows.length === 0){
          this.$bvToast.toast('Unable to download! All items on this batch are open.', {
            title: 'Warning',
            variant: 'warning',
            solid: true
          })
        }else{
          await generateExcel({ header, rows, sheetname }, `${sheetname}.xlsx`)
        }
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: `Error`,
          variant: 'danger',
          solid: true
        })
      }finally{
        this.directDownloadLoading = false
      }
    }
  }
}
</script>